import { useQuery } from '@apollo/client';
import {
  CustomRoute,
  ErrorFallback,
  PageLayout,
  PrivatePageLoading,
  SwitchWithPageNotFound,
} from 'candidate/components';
import { useAppInitData, useCandidateAuth } from 'candidate/utils';
import { signOut } from 'global/auth';
import { firebaseAuth } from 'global/firebaseApp';
import moment from 'moment';
import { useState } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useRedirection } from 'global/utils';
import { ErrorBoundary } from '@sentry/react';
import { Box, Heading, Tab, TabList, Tabs, Text } from '@terminal/design-system';
import { Candidate_Job_Workflow_Status_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { useHandpickedJobsByTPController } from 'candidate/shared/modules/job/useHandpickedJobsByTPController';
import { HandpickedJobsBanner } from 'candidate/shared/modules/job/HandpickedJobsBanner';
import * as events from 'global/events';
import { SelectMyJobs } from '../../shared/modules/job/MyJobs/graphql';
import type {
  SelectMyJobsQuery,
  SelectMyJobsQueryVariables,
} from '../../shared/modules/job/MyJobs/graphql';
import { MyJobsPage } from './MyJobsPage';
import { myJobsSerializer } from '../../shared/modules/job/MyJobs/myJobs.serializer';
import Suitcase from './assets/suitecase.svg?url';
import PersonThinking from './assets/person_thinking.svg?url';
import Watch from './assets/watch.svg?url';

export function MyJobsController({
  /**
   * We are fetching the feed from the last 3 months. This prop is mainly used by storybook
   * to workaround a limitation of mock data feedInitialDate being different than that of the controller
   * by a few milliseconds which causes the mock fixture not resolving.
   */
  feedInitialDate,
}: {
  feedInitialDate?: string;
}) {
  const { user, userFullName } = useCandidateAuth();
  const { candidateAvailability } = useAppInitData({
    userID: user?.id,
  });

  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const redirectTo = useRedirection();
  const { params = { jobID: null } } = useRouteMatch<{ jobID: string }>(`${path}/**/:jobID`) || {};
  const [jobIDSelected, setJobIDSelected] = useState<number | null>(Number(params.jobID));
  // We are fetching the feed from the last 3 months
  const [feedInitialDateState] = useState(feedInitialDate || moment().subtract(3, 'month'));
  const [tabIndex, setTabIndex] = useState(0);

  const { applyToJob, isLoading_applyToJob, declineJob, isLoading_declineJob } =
    useHandpickedJobsByTPController({
      userID: user?.id as number,
      feedInitialDate: feedInitialDateState,
      onComplete: () => {
        setJobIDSelected(null);
      },
    });

  const { data, loading } = useQuery<SelectMyJobsQuery, SelectMyJobsQueryVariables>(SelectMyJobs, {
    variables: {
      user_id: user?.id as number,
      feed_initial_date: feedInitialDateState,
    },
    skip: !user?.id,
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <PrivatePageLoading
        onSignOutClick={() => signOut({ auth: firebaseAuth })}
        {...{ userFullName, candidateAvailability }}
      />
    );
  }

  const {
    appliedJobs,
    archivedJobs,
    talentPartnerSelectedJobs_toReview,
    shouldShowHandpickedJobsBanner,
    isTalentPartnerAssigned,
    talentPartnerEmail,
  } = myJobsSerializer({
    data: data as SelectMyJobsQuery,
  });

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const handleApplyButtonClick = (jobID: number | null) => {
    const workflowID = talentPartnerSelectedJobs_toReview?.find(
      (job) => job.jobID === jobID,
    )?.workflowID;

    if (!workflowID)
      throw new Error(`The selected job with ID:${jobID} that was applied did not have workflowID`);

    applyToJob({
      variables: {
        workflowId: workflowID,
        status: Candidate_Job_Workflow_Status_Choices_Enum.CandidateApproved,
      },
    });
  };

  const handleDeclinedButtonClick = (jobID: number | null) => {
    const workflowID = talentPartnerSelectedJobs_toReview?.find(
      (job) => job.jobID === jobID,
    )?.workflowID;

    if (!workflowID)
      throw new Error(`The selected job with ID:${jobID} that was applied did not have workflowID`);

    declineJob({
      variables: {
        workflowId: workflowID,
        status: Candidate_Job_Workflow_Status_Choices_Enum.CandidateRejected,
      },
    });
  };

  const initialTabIndex = [
    isTalentPartnerAssigned && '/my-jobs/to-review',
    '/my-jobs/applied',
    '/my-jobs/archived',
  ]
    .filter(Boolean)
    .findIndex((tab) => tab === pathname);

  return (
    <ErrorBoundary
      fallback={<ErrorFallback onActionClick={() => redirectTo('/')} actionTitle="Return Home" />}
    >
      <PageLayout.Variation.WithAllSections
        candidateAvailability={candidateAvailability}
        onSignOutClick={() => signOut({ auth: firebaseAuth })}
        userFullName={userFullName}
      >
        <Heading variant="heading-1">My Jobs</Heading>

        {shouldShowHandpickedJobsBanner && <HandpickedJobsBanner mt={3} shouldShowCTA={false} />}

        <Tabs
          mt={[4, 4, 3]}
          index={initialTabIndex < 0 ? tabIndex : initialTabIndex}
          onChange={handleTabsChange}
        >
          <TabList>
            {isTalentPartnerAssigned && (
              <Tab onClick={() => redirectTo(`${path}/to-review`)}>
                To Review{' '}
                <Box ml={4} backgroundColor="ui.lighter.success" px={2} py="2px" borderRadius="5px">
                  <Text variant="hint" fontWeight="bold">
                    {talentPartnerSelectedJobs_toReview.length}
                  </Text>
                </Box>
              </Tab>
            )}
            <Tab onClick={() => redirectTo(`${path}/applied`)}>Applied</Tab>
            <Tab onClick={() => redirectTo(`${path}/archived`)}>Archived</Tab>
          </TabList>
        </Tabs>
        <SwitchWithPageNotFound>
          <CustomRoute
            title="To Review Jobs"
            exact
            path={['/my-jobs', `${path}/to-review`, `${path}/to-review/:jobID`]}
            conditionalRedirects={[
              {
                condition: pathname === '/my-jobs' && isTalentPartnerAssigned,
                redirectURL: `${path}/to-review`,
              },
              {
                condition: pathname.includes('/my-jobs') && !isTalentPartnerAssigned,
                redirectURL: `${path}/applied`,
              },
            ]}
          >
            <MyJobsPage
              jobs={talentPartnerSelectedJobs_toReview}
              selectedJob={
                talentPartnerSelectedJobs_toReview?.find((job) => job.jobID === jobIDSelected) ||
                null
              }
              emptyStateCopies={{
                image: Watch,
                title: 'You Have No Jobs to Review',
                description:
                  'Here you can find all the jobs your Talent Partner wants you to review.',
                ctaText: 'Contact Talent Partner',
                onClickCTA: () => {
                  window.open(`mailto:${talentPartnerEmail}`);
                },
              }}
              onJobDetailClose={() => {
                setJobIDSelected(null);
                redirectTo(`${path}/to-review`);
              }}
              onCardClick={(jobID) => {
                setJobIDSelected(jobID);
                redirectTo(`${path}/to-review/${Number(jobID)}`);
                events.track(events.name.ctaClicked, {
                  job_id: jobID,
                  name: 'talent-partner-suggestion',
                });
              }}
              handleApplyButtonClick={() => handleApplyButtonClick(jobIDSelected)}
              isApplyButtonLoading={isLoading_applyToJob}
              handleDeclinedButtonClick={() => handleDeclinedButtonClick(jobIDSelected)}
              isDeclinedButtonLoading={isLoading_declineJob}
            />
          </CustomRoute>

          <CustomRoute
            title="Applied Jobs"
            exact
            path={['/my-jobs', `${path}/applied`, `${path}/applied/:jobID`]}
            conditionalRedirects={[
              {
                condition: pathname === '/my-jobs',
                redirectURL: `${path}/applied`,
              },
            ]}
          >
            <MyJobsPage
              jobs={appliedJobs}
              selectedJob={appliedJobs.find((job) => job.jobID === jobIDSelected) || null}
              emptyStateCopies={{
                image: Suitcase,
                title: "You Haven't Applied to Any Jobs Yet.",
                description: 'Look at our open jobs!',
                ctaText: 'Browse Jobs',
                onClickCTA: () => redirectTo('/job/matches'),
              }}
              onJobDetailClose={() => {
                setJobIDSelected(null);
                redirectTo(`${path}/applied`);
              }}
              onCardClick={(jobID) => {
                setJobIDSelected(jobID);
                redirectTo(`${path}/applied/${Number(jobID)}`);
              }}
            />
          </CustomRoute>

          <CustomRoute
            title="Archived Jobs"
            exact
            path={[`${path}/archived`, `${path}/archived/:jobID`]}
          >
            <MyJobsPage
              jobs={archivedJobs}
              selectedJob={archivedJobs.find((job) => job.jobID === jobIDSelected) || null}
              emptyStateCopies={{
                image: PersonThinking,
                title: 'There Are No Archived Jobs Yet',
                description: "Here you'll find jobs that closed.",
              }}
              onJobDetailClose={() => {
                setJobIDSelected(null);
                redirectTo(`${path}/archived`);
              }}
              onCardClick={(jobID) => {
                setJobIDSelected(jobID);
                redirectTo(`${path}/archived/${Number(jobID)}`);
              }}
            />
          </CustomRoute>
        </SwitchWithPageNotFound>
      </PageLayout.Variation.WithAllSections>
    </ErrorBoundary>
  );
}
